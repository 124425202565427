import React, { Component } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get'
import JobHero from '../components/job/JobHero';
import JobContent from '../components/job/JobContent';
import ApplicationForm from '../components/job/ApplicationForm';
import theme from '../styles/theme';
import IntersectionObserver from "../components/core/IntersectionObserver";

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  box-sizing: border-box;
`;

const BlackBackground = styled.div`
  width: 100%;
  background:  ${theme.colors.black};
  box-sizing: border-box;
`;

class OfferTemplate extends Component {
  state = {
    theme: "dark"
  };

  changeLogo = (shouldSwitch) => {
    let theme = "dark";

    if (shouldSwitch) {
      theme = "light";
    }

    this.setState({
      theme: theme
    })
  };

  render() {
    const path = this.props.location.pathname;
    const job = get(this, 'props.data.contentfulJob');
    const {changeLogo} = this;

    return (
      <LayoutStyled colorScheme={this.state.theme}>
        <SEO title={job.title}
          fullTitle={true}
          description={job.description.json.content[0].content[0].value}
          path={path} />
        <WhiteBackground>
          <JobHero
            title={job.title}
            intro={job.shortDescription.shortDescription}
            image={job.image.fluid} />
          <JobContent
            description={documentToReactComponents(job.description.json)} />
        </WhiteBackground>
        <IntersectionObserver onChange={changeLogo}>
          <BlackBackground>
            <ApplicationForm />
          </BlackBackground>
        </IntersectionObserver>
      </LayoutStyled>
    )
  }
}

export default OfferTemplate;

export const pageQuery = graphql`
  query JobBySlug($slug: String!) {
    contentfulJob(slug: {eq: $slug}) {
      title
      slug
      location
      shortDescription {
        shortDescription
      }
      description {
        json
      }
      image: image {
        fluid(
          maxWidth: 1000
          quality: 60
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }`;


  // <h1></h1>
  // <div><Img fluid={job.image.fluid}/></div>
  // <div></div>
