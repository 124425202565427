import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import theme from '../../styles/theme';
import { Container } from 'styled-bootstrap-grid';

const JobContentContainer = styled(Container)`
    background: url(${Circle}) no-repeat;
    margin-top: 0px;
    padding-top: 65px;
    padding-bottom: 60px;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-size: 330px;
    background-position-x: 80px;
    background-position-y: 175px;
    @media(min-width: ${theme.breakpoints.medium}) {
        margin: auto;
        padding-top: 115px;
        padding-bottom: 130px;
        padding-left: 120px;
        padding-right: 120px;
        flex-direction: row;
        background-position-x: calc(100% - 200px);
        background-position-y: 140px;
        background-size: 580px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 310px;
        padding-right: 310px;
    }
`;

const ContentContainer = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.black};   
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    
    p {
      margin-bottom: 12px;
    }
    
    b {
      ${fonts.MaisonNeueDemi};
    }
    
    ul {
      margin-left: 20px;
    
      li {
        list-style: disc;
      }
    }
    
    @media(min-width: ${theme.breakpoints.medium}) {
        max-width: 850px;
        font-size: 18px;
        line-height: 28px;
    }
`;

const JobContent = (props) => {

    const { description } = props;
    return (
        <JobContentContainer>
            <ContentContainer>
                {description}
            </ContentContainer>
        </JobContentContainer>
    )
};

export default JobContent;