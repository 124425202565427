import React from 'react';
import styled from 'styled-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Form from './Form';
import fonts from '../../styles/fonts';
import theme from '../../styles/theme';



const ApplicationFormContainer = styled(Container)`
    box-sizing: border-box;
    padding: 35px;
    padding-top: 70px;
    padding-bottom: 100px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 130px;
        padding-bottom: 115px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-right: 186px;
        padding-left: 186px;
    }
`;

const ApplyTitle = styled.div`
    ${fonts.CircularMedium};
    color: ${theme.colors.white};
    padding-top: 20px;
    font-size: 35px;
    line-height: 42px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 62px;
        line-height: 76px;
    }
`;



const ApplicationForm = () => {
    return (
        <ApplicationFormContainer className="cursor-white">
            <Row>
                <Col md={5}>
                    <ApplyTitle>Apply Now ...</ApplyTitle>
                </Col>
                <Col md={7}>
                    <Form />
                </Col>
            </Row>
        </ApplicationFormContainer>
    )
}

export default ApplicationForm;
