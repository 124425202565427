import React, {useEffect, useRef} from "react"

const LOGO_OFFSET = 220 - 45;

const IntersectionObserver = ({onChange, children}) => {
  const elementRef = useRef();

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("scroll", handleScroll);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const element = elementRef.current;

    const elementOffsetTop = element.offsetTop;
    const elementHeight = - element.clientHeight;
    const windowOffset = window.pageYOffset;

    const diff = elementOffsetTop - windowOffset - LOGO_OFFSET;

    const shouldSwitch = diff < 0 && diff > elementHeight;

    return onChange(shouldSwitch);
  };

  // const onChange = () => {
  //   elementRef.current;
  // };

  // const childrenComponent = React.cloneElement(children, {
  //   submit: submit,
  //   status: status,
  //   message: message,
  // });

  return (
    <div ref={elementRef}>
      {children}
    </div>
  )
};

export default IntersectionObserver;
