import React  from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import Img from 'gatsby-image';
import theme from '../../styles/theme';

const RowStyled = styled(Row)`
    position: relative;
    padding-bottom: ${props => props.filter ? '0px' : '70px'};
    padding-top: ${props => props.bottom && '70px'};
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-bottom: 100px;
    }
`;

const ColStyled = styled(Col)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    display: ${props => props.desktop ? 'none' : 'flex'};
    @media(min-width: ${theme.breakpoints.medium}) {
        display: ${props => props.mobile && 'none'};
        display: ${props => props.desktop && 'flex'};
    }

`;

const JobHeroContainer = styled(Container)`
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    padding-top: 115px;
    background: url(${Circle}) no-repeat;
    background-size: 330px;
    background-position-x: calc(100% + 40px);
    background-position-y: 115px;
    margin: auto;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 200px;
        background-position-x: 320px;
        background-position-y: 160px;
        background-size: 580px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 184px;
        padding-right: 184px;
    }

`;

const JobTitle = styled.div`
    ${fonts.CircularMedium};
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.black};
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 32px;
        line-height: 40px;
    }
`;

const JobIntro = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.black};
    padding-top: 35px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
    }

`;

const HeroImageContainer = styled.div`
    height: 260px;
    width: calc(100% + 70px);
    margin: auto;
    margin-left: -35px;
    margin-right: -35px;
    overflow: hidden;
    position: relative;
    @media(min-width: ${theme.breakpoints.medium}) {
        max-height: 680px;
        height: auto;
    }
`;

const HeroImage = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const JobHero = (props) => {

    const { title, intro, image } = props;
    return (
        <JobHeroContainer>
            <RowStyled>
                <ColStyled md={8} lg={6} mdOffset={4} lgOffset={6}>
                    <JobTitle>{title}</JobTitle>
                    <JobIntro>{intro}</JobIntro>
                </ColStyled>
            </RowStyled>
            <HeroImageContainer>
                <HeroImage fluid={image} />
            </HeroImageContainer>
        </JobHeroContainer>

    )
}

export default JobHero;